import React, { FC, useEffect, useRef, useState } from 'react';
import {
  query,
  collection,
  onSnapshot,
  DocumentData,
  QueryConstraint,
  updateDoc,
  doc,
} from 'firebase/firestore';
import { BhabiFirestoreModel, Message } from './message';
import { SendMessage, SendMessageProps } from './send-message';
import { db, firebaseAuth } from '@/shared/lib';
import { useAuthState } from 'react-firebase-hooks/auth';
import { ErrorLayout } from '@/shared/components';

interface ChatBoxProps {
  queryConstraint?: QueryConstraint[];
  sendMessageProps?: Omit<SendMessageProps, 'scroll'>;
  outerBoxProps?: React.HTMLAttributes<HTMLDivElement>;
}

export const useFirestoreQuery = (queryConstraint: QueryConstraint[]) => {
  const [messages, setMessages] = useState<DocumentData[]>([]);

  useEffect(() => {
    if (!queryConstraint.length) {
      return;
    }
    const q = query(collection(db, 'chat'), ...queryConstraint);

    const unsubscribe = onSnapshot(q, QuerySnapshot => {
      const fetchedMessages: DocumentData[] = [];
      QuerySnapshot.forEach(doc => {
        fetchedMessages.push({ ...doc.data(), id: doc.id });
      });
      const sortedMessages = fetchedMessages.sort(
        (a, b) => a.createdAt - b.createdAt
      );
      setMessages(sortedMessages);
    });

    return () => {
      unsubscribe();
    };
  }, [queryConstraint]);

  return messages;
};

export const ChatBox: FC<ChatBoxProps> = ({
  queryConstraint = [],
  sendMessageProps,
  outerBoxProps,
}) => {
  const scroll = useRef<HTMLSpanElement>(null);
  const messages = useFirestoreQuery(queryConstraint);
  const [user] = useAuthState(firebaseAuth);

  useEffect(() => {
    if (!user?.uid) {
      return;
    }

    (messages as BhabiFirestoreModel[]).forEach(message => {
      if (message?.isRead === 'false' && message?.senderID !== user.uid) {
        (async function () {
          await updateDoc(doc(db, 'chat', message.id), 'isRead', 'true');
        })();
      }
    });
  }, [messages, user?.uid]);

  useEffect(() => {
    scroll.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return (
    <div className="h-[93vh] flex flex-col" {...outerBoxProps}>
      <div className="flex-1 overflow-y-scroll px-4 py-2 flex flex-col items-end pt-6">
        {messages?.length ? (
          messages.map(message => (
            <Message
              key={message.id}
              message={message as BhabiFirestoreModel}
            />
          ))
        ) : (
          <div className="flex flex-col justify-end items-center min-h-96 mb-5 w-full">
            <ErrorLayout
              level="info"
              outerBoxProps={{ height: 'auto' }}
              texts={{
                defaultError: `Send a message to start chat.`,
              }}
            />
          </div>
        )}
        <span ref={scroll} />
      </div>
      <SendMessage {...sendMessageProps} scroll={scroll} />
    </div>
  );
};
