import React, { FC, PropsWithChildren } from 'react';
import {
  CssBaseline,
  Palette,
  ThemeProvider as TP,
  createTheme,
  darkScrollbar,
} from '@mui/material';
import { green, grey } from '@mui/material/colors';
import { useSelector } from 'react-redux';
import { Store } from '../redux';
import './font-family.css';
import { ISO_PADDING } from '@/screens/authenticated/consts';
import { useLandscapeMode } from '@/shared/hooks';

export const darkModePalette: Pick<Palette, 'background'> = {
  background: {
    default: '#28282A',
    paper: '#121212',
  },
};

export const lightModePalette: Pick<Palette, 'background'> = {
  background: {
    default: '#F7F7F9',
    paper: '#f5f5f5',
  },
};

export const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  const { mode } = useSelector((state: Store) => state.themeReducer);

  const isLandscapeMode = useLandscapeMode();

  const theme = createTheme({
    palette: {
      ...(mode === 'dark' && darkModePalette),
      ...(mode === 'light' && lightModePalette),
      mode,
      primary: {
        main: '#DAA520',
        light: '#FAFAD2',
        dark: '#B8860B',
      },
      secondary: {
        main: green[700],
        light: green[400],
      },
    },
    breakpoints: {
      values: {
        md: isLandscapeMode ? 400 : 960,
        lg: 1280,
        xl: 1920,
        sm: 600,
        xs: 0,
      },
    },
    typography: {
      fontFamily: '"Titillium Web", sans-serif',
      fontSize: 12,
      h1: {
        fontSize: '3rem',
        '@media only screen and (max-width: 600px)': {
          fontSize: '1.5rem',
        },
      },
      h2: {
        fontSize: '2rem',
        '@media only screen and (max-width: 600px)': {
          fontSize: '1.3125rem',
        },
      },
      h3: {
        fontSize: '1.75rem',
        '@media only screen and (max-width: 600px)': {
          fontSize: '1.125rem',
        },
      },
      h4: {
        fontSize: '1.50rem',
        '@media only screen and (max-width: 600px)': {
          fontSize: '0.9375rem',
        },
      },
      h5: {
        fontSize: '1.25rem',
        '@media only screen and (max-width: 600px)': {
          fontSize: '0.85rem',
        },
      },
      h6: {
        fontSize: '1rem',
        '@media only screen and (max-width: 600px)': {
          fontSize: '0.7rem',
        },
      },
    },
    spacing: (factor: number) => `${factor * 6}px`,
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          fontFamily: '"Titillium Web", sans-serif',
          html: {
            ...darkScrollbar(
              mode === 'light'
                ? {
                    track: grey[200],
                    thumb: grey[400],
                    active: grey[400],
                  }
                : undefined
            ),
            //scrollbarWidth for Firefox
            scrollbarWidth: 'thin',
          },
          body: {
            position: 'relative',
            minHeight: '100vh',
            fontSize: 14,
            zIndex: 0,
          },
          'body::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: '100%',
            height: '100%',
            backgroundImage: `url(${process.env.PUBLIC_URL}/background/${
              mode === 'light' ? 'light.svg' : 'dark.jpeg'
            })`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            opacity: mode === 'light' ? 0.5 : 0.1,
            zIndex: -1,
            paddingLeft: ISO_PADDING,
            paddingRight: ISO_PADDING,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            fontSize: 16,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            minWidth: 75,
            height: 35,
            fontSize: 14,
            fontWeight: 600,
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: 14,
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8,
            borderTopRightRadius: 8,
            borderBottomRightRadius: 0,
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            borderRadius: 8,
          },
        },
      },
      MuiIconButton: {
        defaultProps: {
          sx: {
            borderRadius: 4,
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiInputBase-root': {
              height: 38,
            },
          },
        },
      },
    },
  });

  return (
    <TP theme={theme}>
      <CssBaseline enableColorScheme />
      {children}
    </TP>
  );
};
