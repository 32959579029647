import React, { FC, useEffect, useMemo, useRef, useState } from 'react';

import { dispatch, Store } from '@/redux';
import { useSelector } from 'react-redux';
import { LoggerService } from '@/shared/logger';
import { Card, InPlayRoomWsResponse, ThrowCardResponse } from '../types';
import { CardPosition, CardsWithLayout, DeckCard } from './components';
import {
  Box,
  Button,
  Theme,
  Typography,
  styled,
  useMediaQuery,
} from '@mui/material';
import {
  BhabiLink,
  ErrorLayout,
  LoadingLayout,
  useAlert,
  usePopUpBox,
} from '@/shared/components';
import { InRoomPlayerList, Playground } from './components/playground';
import { useHttpMutation } from '@/api';
import { API_URLS } from '../consts';
import { ThrowCardProvider, useThrowCard } from './context';
import { useBotPlay, useThrowCardAnimation } from './hooks';
import { BhabiStorage, adMob } from '@/shared/lib';
import { useLeaveRoom } from '../components';
import { INVITE_PLAYER_ROUTE } from '../invite-players';
import { useSendSocketMessage } from '../send-message-ctx';
import { noop } from 'lodash';
import { useLandscapeMode } from '@/shared/hooks';
import { SendMessage, useWebsocket } from '../use-websocket';
import { WEBSOCKET_URLS } from '@/shared/consts';
import { setInPlayState } from '@/redux/reducers';
// import { useInPlayPageAdMob } from '../hooks/use-in-play-admob';

export const InPlayPageWithoutCtx: FC = () => {
  const { id: userID } = useSelector((state: Store) => state.userReducer);
  const {
    socketError,
    sendMessage,
    socketResponse: inPlayResponse,
  } = useWebsocket<InPlayRoomWsResponse>(WEBSOCKET_URLS().ROOM);

  useEffect(() => {
    dispatch(setInPlayState(inPlayResponse));
  }, [inPlayResponse]);

  const { gameInfo } = inPlayResponse || {
    gameInfo: null,
  };
  const { gameState, setGameState } = useThrowCard();
  useBotPlay(inPlayResponse!, sendMessage);

  useEffect(() => {
    if (gameInfo?.room?.isFull) {
      return;
    }

    const t = setInterval(() => {
      sendMessage('ping');
    }, 1500);

    return () => {
      clearInterval(t);
    };
  }, [gameInfo, sendMessage]);

  const { setSendMessage, sendMessage: msgSend } = useSendSocketMessage();

  useEffect(() => {
    if (sendMessage && !msgSend) {
      LoggerService.info('Setting sendMessage in InPlayPage');
      setSendMessage(() => sendMessage);
    }
  }, [sendMessage, setSendMessage, msgSend]);

  useEffect(() => {
    const showFullscreenAd = async () => {
      if (!inPlayResponse?.gameInfo.room?.roomID) {
        return;
      }

      const storedKey = await BhabiStorage.getItem(noop, 'currentAdMobRoomID');

      if (storedKey === inPlayResponse?.gameInfo.room?.roomID) {
        return;
      }
      BhabiStorage.setItem(
        'currentAdMobRoomID',
        inPlayResponse?.gameInfo.room?.roomID
      );

      await adMob.interstitial();
    };

    showFullscreenAd();
  }, [inPlayResponse]);

  useEffect(() => {
    if (gameState === 'clear') {
      sendMessage('clear');
      setGameState('playing');
    }
  }, [gameState, setGameState, sendMessage]);

  const [selectedCard, setSelectedCard] = useState<{
    card: Card;
    pos: CardPosition;
  } | null>(null);

  const onClickCard = (card: Card, pos: CardPosition) => () => {
    setSelectedCard({ card, pos });
  };

  const userDeck = useMemo(
    () => inPlayResponse?.gameInfo?.userDeck || [],
    [inPlayResponse]
  );

  const lockDrag = useMediaQuery<Theme>(theme => theme.breakpoints.up('lg'));

  // const manageAd = useInPlayPageAdMob();

  // useEffect(() => {
  //   manageAd(false);
  //   return () => {
  //     adMob.removeBanner();
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const leaveRoom = useLeaveRoom(sendMessage);
  const { addPopUpBox } = usePopUpBox();

  useEffect(() => {
    if (
      inPlayResponse?.missingOwner &&
      inPlayResponse?.players.some(p => p.userType === 'bot')
    ) {
      addPopUpBox(
        {
          message: `Room owner ${inPlayResponse?.ownerUsername} is offline.
          DM ${inPlayResponse?.ownerUsername} to re-join.`,
          callback: noop,
        },
        {
          texts: {
            title: 'Room Owner is Offline',
            yes: '',
            no: 'OK',
          },
          yesButtonProps: {
            showYesButton: false,
          },
        }
      );
    }
  }, [
    addPopUpBox,
    inPlayResponse?.missingOwner,
    inPlayResponse?.ownerUsername,
    inPlayResponse?.players,
  ]);

  const showWinMessage = useRef(false);
  useEffect(() => {
    if (
      inPlayResponse?.gameInfo?.inPlayCards?.length == 0 &&
      !inPlayResponse?.gameInfo?.userDeck?.length &&
      inPlayResponse?.gameInfo?.room?.nextTurn !== userID &&
      !showWinMessage.current
    ) {
      LoggerService.info('User has no cards left, user won the game');
      showWinMessage.current = true;
      addPopUpBox(
        {
          message: 'You won the game Great Job! 🎉',
          callback: noop,
        },
        {
          texts: {
            title: 'Congratulations',
            yes: '',
            no: 'OK',
          },
          yesButtonProps: {
            showYesButton: false,
          },
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inPlayResponse]);

  const isLandscapeMode = useLandscapeMode();

  if (!isLandscapeMode) {
    return (
      <ErrorLayout
        level="warning"
        texts={{ defaultError: 'Rotate your device to start the Game.' }}
        outerBoxProps={{
          height: '50vh',
        }}
      />
    );
  }

  if (!inPlayResponse && !socketError) {
    return <LoadingLayout height="auto" />;
  }

  if (!inPlayResponse && socketError) {
    return (
      <ErrorLayout
        level="error"
        texts={{ defaultError: 'Something went wrong, try rejoining room.' }}
        outerBoxProps={{
          height: 'auto',
        }}
      >
        <Button
          variant="contained"
          onClick={leaveRoom(
            `Are you sure you want to leave this room?`,
            false,
            [INVITE_PLAYER_ROUTE.ABSOLUTE_PATH, 'tab=Rooms'].join('?')
          )}
        >
          Leave Room
        </Button>
      </ErrorLayout>
    );
  }

  return (
    <div className="relative h-[89vh] max-h-[1000px] overflow-y-hidden pt-2">
      {!gameInfo?.room?.isFull && inPlayResponse?.players?.length ? (
        <div className="flex flex-col justify-center">
          <ErrorLayout
            level="info"
            texts={{ defaultError: 'Waiting for all the players to join' }}
            outerBoxProps={{
              height: 'auto',
              sx: {
                justifyContent: 'flex-start',
                gap: 0.5,
              },
            }}
          />
          <Typography variant="body1" sx={{ textAlign: 'center' }}>
            Don`t have enough players?{' '}
            {
              <BhabiLink
                to={[INVITE_PLAYER_ROUTE.ABSOLUTE_PATH, 'tab=Bots'].join('?')}
              >
                Invite bots
              </BhabiLink>
            }{' '}
            to start the game.
          </Typography>
          {inPlayResponse && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <InRoomPlayerList
                nextTurn={inPlayResponse.gameInfo.room?.nextTurn ?? ''}
                noOfPlayers={inPlayResponse.gameInfo.room?.numberOfPlayers ?? 0}
                players={inPlayResponse.players}
                isHorizontal
              />
            </Box>
          )}
        </div>
      ) : (
        inPlayResponse && (
          <Playground inPlayWS={inPlayResponse} sendMessage={sendMessage} />
        )
      )}
      {selectedCard && gameInfo?.room?.isFull && (
        <ConfirmCardBox
          {...{
            card: selectedCard?.card,
            pos: selectedCard.pos,
            setSelectedCard,
            sendMessage,
          }}
        />
      )}
      <InPlayPageWrapper>
        {userDeck && (
          <CardsWithLayout
            {...{
              cards: userDeck.map(
                addCardDisableStatus(
                  inPlayResponse?.gameInfo?.room?.nextTurn !== userID ||
                    !gameInfo?.room?.isFull,
                  inPlayResponse?.gameInfo?.inPlayCards?.[0]?.suite,
                  userDeck
                )
              ),
              onClickCard,
              selectedCard: selectedCard?.card,
              lockDrag,
            }}
          />
        )}
      </InPlayPageWrapper>
    </div>
  );
};

function addCardDisableStatus(
  disableAll: boolean,
  firstCardSuite?: Card['suite'],
  userDeck?: Card[]
) {
  return (card: Card) => {
    if (disableAll) {
      return {
        ...card,
        disabled: true,
      };
    }

    if (
      firstCardSuite &&
      card.suite !== firstCardSuite &&
      userDeck?.some(({ suite }) => suite === firstCardSuite)
    ) {
      return {
        ...card,
        disabled: true,
      };
    }

    return {
      ...card,
      disabled: false,
    };
  };
}

const InPlayPageWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 20,
  left: 0,
  marginBottom: theme.spacing(2),
  width: '100%',
  maxWidth: '100%',
}));

const ConfirmCardBoxStyled = styled(Box, {
  shouldForwardProp: prop => prop !== 'pos',
})<{ pos: CardPosition }>(({ theme, pos }) => ({
  position: 'absolute',
  top: pos.top - 70,
  left: 0,
  width: '100%',
  paddingBottom: theme.spacing(2),
  zIndex: 1000,
}));

interface ConfirmCardBoxProps {
  card: Card;
  setSelectedCard: React.Dispatch<
    React.SetStateAction<{
      card: Card;
      pos: CardPosition;
    } | null>
  >;
  sendMessage: SendMessage;
  pos: CardPosition;
}
const ConfirmCardBox: FC<ConfirmCardBoxProps> = ({
  card,
  setSelectedCard,
  sendMessage,
  pos,
}) => {
  const { mutate: throwCard } = useHttpMutation<ThrowCardResponse, Card>(
    API_URLS.IN_PLAY,
    'POST'
  );

  const { setThrownCard, thrownCard } = useThrowCard();
  const { addAlert } = useAlert();

  const animateProps = useThrowCardAnimation(card, setSelectedCard);

  const onClickCard = (card: Card) => () => {
    throwCard(card, {
      onSuccess: async () => {
        setThrownCard(card);
        await new Promise(resolve => setTimeout(resolve, 1000));
        sendMessage('throw-card');
      },
      onError: err => {
        addAlert({
          message: err?.response?.data?.message || 'Something went wrong',
          type: 'error',
        });
      },
    });
  };

  return (
    <ConfirmCardBoxStyled pos={pos}>
      <Box
        sx={{
          position: 'absolute',
          left: pos.left,
        }}
      >
        <DeckCard
          card={card}
          onClick={onClickCard(card)}
          motionDivProps={thrownCard ? animateProps : undefined}
          tooltip
        />
      </Box>
    </ConfirmCardBoxStyled>
  );
};

export const InPlayPage: FC = () => (
  <ThrowCardProvider>
    <InPlayPageWithoutCtx />
  </ThrowCardProvider>
);
