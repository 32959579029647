import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import HomeIcon from '@mui/icons-material/Home';
import Box, { BoxProps } from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { Badge, Typography, useTheme } from '@mui/material';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { INVITE_PLAYER_ROUTE } from '../invite-players';
import { InviteNotifier } from './invite-notifier';
import { ProfileButton } from './profile-button';
import { useSelector } from 'react-redux';
import { Store } from '../../../redux';
import { IN_PLAY_ROUTE } from '../in-play';
import { BhabiLogo, DownIcon, UpIcon } from '../../../assets';
import { LeaveRoom } from './leave-room';
import {
  BhabiLink,
  TopBarLayout,
  UserAvatar,
} from '../../../shared/components';
import { DM_PAGE_ROUTE } from '../chat';
import { useDmPageQuery, useGetFirebaseUsers } from '../chat/dm';
import { PUBLIC_PROFILE_ROUTE } from '../profile';
import { HOME_ROUTE } from '../home';
import { isFunction } from 'lodash';
import { ISO_PADDING } from '../consts';
import { BiMessageSquareAdd } from 'react-icons/bi';
import { BsPeople } from 'react-icons/bs';
import { SendMessage } from '../use-websocket';
interface TopBarProps {
  sendMessage?: SendMessage;
  children?: (props: { isTopBarVisible: boolean }) => JSX.Element;
}

export const PrimaryTopBar: FC<TopBarProps> = ({ sendMessage, children }) => {
  const nav = useNavigate();
  const isInRoom = useSelector((state: Store) => state.userReducer.isInRoom);
  const room = useSelector(
    (state: Store) => state.inPlayReducer.gameInfo?.room
  );

  const [slideTopBar, setSlideTopBar] = useState(false);
  const { pathname } = useLocation();

  return (
    <>
      <TopBarLayout
        outerBoxProps={{
          hidden: slideTopBar,
        }}
      >
        <TopBarLogoOrBackButton isInRoom={!!isInRoom} />
        <ChatNotification />
        {!room?.isFull && (
          <>
            <IconButton
              size="small"
              onClick={() => {
                nav(INVITE_PLAYER_ROUTE.ABSOLUTE_PATH);
              }}
            >
              <BsPeople fontSize={28} />
            </IconButton>
            <InviteNotifier />
          </>
        )}

        <ProfileButton hideLogout={isInRoom} />
        {isInRoom && <LeaveRoom sendMessage={sendMessage} />}
      </TopBarLayout>
      {/*Disabling this feature for now. */}
      {isInRoom && pathname === IN_PLAY_ROUTE.ABSOLUTE_PATH && false && (
        <CollapseTopBar
          setSlideTopBar={setSlideTopBar}
          slideTopBar={slideTopBar}
        />
      )}

      {isFunction(children) && children({ isTopBarVisible: !slideTopBar })}
    </>
  );
};

export const CollapseTopBar: FC<{
  setSlideTopBar: Dispatch<SetStateAction<boolean>>;
  slideTopBar: boolean;
}> = ({ setSlideTopBar, slideTopBar }) => {
  const Icon = slideTopBar ? DownIcon : UpIcon;
  return (
    <Box
      {...{
        position: 'fixed',
        top: !slideTopBar ? 42 : 1,
        left: '47%',
        zIndex: 3000,
        pt: ISO_PADDING,
      }}
    >
      <Icon
        sx={{
          cursor: 'pointer',
          fontSize: 40,
        }}
        onClick={() => {
          setSlideTopBar(prev => !prev);
        }}
      />
    </Box>
  );
};

export const TopBarLogoOrBackButton: FC<{
  outerBoxProps?: BoxProps;
  isInRoom: boolean;
}> = ({ outerBoxProps, isInRoom }) => {
  const { pathname } = useLocation();

  const nav = useNavigate();

  const homePage = isInRoom
    ? IN_PLAY_ROUTE.ABSOLUTE_PATH
    : HOME_ROUTE.ABSOLUTE_PATH;

  return (
    <Box {...outerBoxProps}>
      {pathname === HOME_ROUTE.ABSOLUTE_PATH ||
      pathname === IN_PLAY_ROUTE.ABSOLUTE_PATH ? (
        <BhabiLink to={homePage}>
          <BhabiLogo
            style={{
              height: 50,
              width: 50,
            }}
          />
        </BhabiLink>
      ) : (
        <Box display="flex" alignItems="center" gap={0.5}>
          <Box>
            <IconButton
              onClick={() => {
                nav(-1);
              }}
            >
              <ArrowBackOutlinedIcon fontSize="small" />
            </IconButton>
          </Box>

          <HomeButton homePage={homePage} />
        </Box>
      )}
    </Box>
  );
};

const HomeButton: FC<{ homePage: string }> = ({ homePage }) => {
  const [params] = useSearchParams();
  const receiverID = params.get('receiverID');
  const { mutate: getUserInfo, data } = useGetFirebaseUsers();
  const theme = useTheme();

  useEffect(() => {
    if (!receiverID) {
      return;
    }

    getUserInfo([receiverID]);
  }, [getUserInfo, receiverID]);

  return (
    <>
      {receiverID && data?.[0] ? (
        <Box
          {...{
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
          }}
        >
          <UserAvatar
            userState={data[0].state}
            photoURL={data[0].photoURL}
            name={data[0].name}
            avatarProps={{
              sx: {
                width: 25,
                height: 25,
              },
            }}
          />
          <BhabiLink
            to={[PUBLIC_PROFILE_ROUTE.EXACT_PATH, '?user_id=', data[0].id].join(
              ''
            )}
            style={{
              color: theme.palette.text.primary,
              textDecoration: 'none',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography variant="body2">{data[0].name}</Typography>
            <NavigateNextIcon />
          </BhabiLink>
        </Box>
      ) : (
        <BhabiLink to={homePage}>
          <IconButton>
            <HomeIcon
              sx={{ color: theme => theme.palette.text.primary }}
              fontSize="small"
            />
          </IconButton>
        </BhabiLink>
      )}
    </>
  );
};

export const ChatNotification: FC = () => {
  const nav = useNavigate();
  const { firebaseUID } = useSelector((state: Store) => state.userReducer);

  const {
    pageData: { uniqueMessagePerUser },
  } = useDmPageQuery(false);

  const messagesNotRead = useMemo(
    () =>
      uniqueMessagePerUser.filter(
        message =>
          message.senderID !== firebaseUID && message.isRead === 'false'
      ).length,
    [uniqueMessagePerUser, firebaseUID]
  );

  return (
    <IconButton
      onClick={() => {
        nav(DM_PAGE_ROUTE.ABSOLUTE_PATH);
      }}
    >
      <Badge badgeContent={messagesNotRead} color="success">
        <BiMessageSquareAdd fontSize={28} />
      </Badge>
    </IconButton>
  );
};
