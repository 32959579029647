import LogoutIcon from '@mui/icons-material/Logout';
import React, { FC } from 'react';
import { useHttpMutation } from '../../../api';
import { API_URLS } from '../consts';
import { useAlert, usePopUpBox } from '../../../shared/components';
import { IconButton, IconButtonProps, Typography } from '@mui/material';
import { BhabiStorage } from '../../../shared/lib';
import { getUser, setInPlayState } from '../../../redux/reducers';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { dispatch, Store } from '../../../redux';
import { noop } from 'lodash';
import { IN_PLAY_ROUTE } from '../in-play';
import { HOME_ROUTE } from '../home';
import { LoggerService } from '@/shared/logger';
import { SendMessage } from '../use-websocket';

export const LeaveRoom: FC<{
  iconButtonProps?: IconButtonProps;
  avoidIOS?: boolean;
  sendMessage?: SendMessage;
}> = ({ iconButtonProps = {}, sendMessage }) => {
  const onClickIconButton = useLeaveRoom(sendMessage);

  return (
    <IconButton
      onClick={onClickIconButton()}
      color="secondary"
      {...{
        ...iconButtonProps,
        edge: 'end',
        sx: {
          ...iconButtonProps.sx,
          padding: theme => theme.spacing(1),
          marginLeft: 1.5,
        },
      }}
    >
      <LogoutIcon
        sx={{ color: theme => theme.palette.text.primary, fontSize: 26 }}
      />
    </IconButton>
  );
};

const useRematch = () => {
  return useHttpMutation(API_URLS.RE_MATCH, 'GET');
};

export const useLeaveRoom = (sendMessage = noop) => {
  const { mutate: leaveRoom } = useHttpMutation<{ token: string }>(
    API_URLS.LEAVE_ROOM,
    'GET'
  );
  const nav = useNavigate();
  const {
    players,
    gameInfo: { room },
  } = useSelector((state: Store) => state.inPlayReducer);
  const isBots = players.some(player => player.userType === 'bot');
  const isRoomOwner = room?.role === 'owner' && !room.isConcluded;
  const isRoomOwnerAndConcluded =
    (room?.role === 'owner' && room.isConcluded) ||
    (room?.role === 'owner' && room.userDeck.length === 0);

  const { addPopUpBox, onClick: hidePopUp } = usePopUpBox();
  const { addAlert } = useAlert();
  const { mutate: rematch, isLoading } = useRematch();

  const onClickRematch = () => {
    rematch(undefined, {
      onSuccess: async () => {
        sendMessage('rematch');
        hidePopUp();
        window.location.assign(IN_PLAY_ROUTE.ABSOLUTE_PATH);
      },
      onError: () => {
        addAlert({
          message: 'Error creating rematch',
          type: 'error',
        });
      },
    });
  };
  const onClickLeaveRoom =
    (
      message = `Are you sure you want to leave the room?`,
      sendOwnerMsg = true,
      navigateTo = HOME_ROUTE.ABSOLUTE_PATH
    ) =>
    () => {
      addPopUpBox(
        {
          message:
            isRoomOwner && isBots && sendOwnerMsg ? (
              <RoomOwnerMessage />
            ) : (
              <Typography fontSize={20}>{message}</Typography>
            ),
          callback: status => {
            if (status) {
              leaveRoom(undefined, {
                onSuccess: res => {
                  BhabiStorage.setToken(res.token);
                  dispatch(getUser(res.token));
                  dispatch(
                    setInPlayState({
                      token: res.token,
                      gameInfo: {
                        room: null,
                        userDeck: null,
                        inPlayCards: null,
                        deck: null,
                      },
                      players: [],
                    })
                  );
                  sendMessage('left-room');
                  nav(navigateTo);
                },
                onError: () => {
                  LoggerService.error('Error leaving room');
                  addAlert({
                    message: 'Error leaving room',
                    type: 'error',
                  });
                },
              });
            }
          },
        },
        {
          extraButtonProps: {
            showExtraButton: isRoomOwnerAndConcluded,
            props: {
              onClick: onClickRematch,
              disabled: isLoading,
            },
          },
          texts: {
            title: 'Leave Room',
            yes: 'Leave',
            no: 'Cancel',
            ...(isRoomOwnerAndConcluded ? { extraButton: 'Rematch' } : {}),
          },
        }
      );
    };

  return onClickLeaveRoom;
};

const RoomOwnerMessage: FC = () => (
  <Typography variant="body2" fontSize={16}>
    Since you are the owner of the room, leaving room will pause all the bots.
    Please wait for the game to finish.
  </Typography>
);
