import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Card,
  InPlayRoomWsResponse,
  LastTurnResult,
  ThrowCardResponse,
} from '../types';
import { Theme, useMediaQuery } from '@mui/material';
import { angle } from './utils';
import { MotionProps, Variants } from 'framer-motion';
import { useHttpMutation } from '../../../api';
import { API_URLS } from '../consts';
import { noop } from 'lodash';
import { useThrowCard } from './context';
import { useSelector } from 'react-redux';
import { Store } from '../../../redux';
import { LoggerService } from '../../../shared/logger';
import { useAlert } from '../../../shared/components';
import { useLocation } from 'react-router-dom';
import { IN_PLAY_ROUTE } from './route.definitions';
import { CardPosition } from './components';

export interface CardsLayoutOptions {
  layout: {
    flat: boolean;
    space: number;
  };
}

export const useCardsLayout = (
  deck: Card[] = [],
  options?: CardsLayoutOptions
) => {
  const { layout } = options || {};

  const { wrapperRef, handWidth } = useParentWidth();
  const sm = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
  const virtualFanWidth = Math.min(handWidth, deck.length * 100);
  const virtualFanHeight = virtualFanWidth * 0.75;
  const space = deck.length > 20 && sm ? 0.08 : 0.05;

  const variantShow = ({ i }: { i: number }) => {
    const ang = angle(deck, layout?.space || space)(i);
    return {
      y: virtualFanHeight * (1 - Math.cos(ang)),
      x: virtualFanWidth * Math.sin(ang),
      rotate: `${ang}rad`,
    };
  };

  const variants: Variants = {
    show: variantShow,
    hidden: {
      transition: { duration: 0.2 },
      y: 300,
    },
  };

  return { handWidth, variants, wrapperRef };
};

export const useParentWidth = () => {
  const wrapperRef = useRef<HTMLElement>(null);
  const [handWidth, setHandWidth] = useState(0);

  useEffect(() => {
    const onReSize = () => setHandWidth(wrapperRef.current!.clientWidth);

    onReSize();

    window.addEventListener('resize', onReSize);

    return () => window.removeEventListener('resize', onReSize);
  }, [setHandWidth]);

  return { wrapperRef, handWidth, setHandWidth };
};

const DELAY = 100;
export const useThokaBazzi = (
  setGridWidth: React.Dispatch<React.SetStateAction<number>>
) => {
  const { mutate: applyBazzi, ...applyBazziOptions } = useHttpMutation(
    API_URLS.BAZZI,
    'GET'
  );
  const { mutate: applyThoka, ...applyThokaOptions } = useHttpMutation(
    API_URLS.THOKA,
    'GET'
  );

  const inPlayCards =
    useSelector((state: Store) => state.inPlayReducer.gameInfo.inPlayCards) ||
    [];

  const sm = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

  const decreaseGridWidthGradually = useCallback(
    async () =>
      new Promise(resolve => {
        setTimeout(
          () => {
            const interval = setInterval(() => {
              setGridWidth(prev => {
                const newVal = prev - (sm ? 4 : 20);
                if (newVal <= 0) {
                  clearInterval(interval);
                  resolve(true);
                  return 0;
                }

                return newVal;
              });
            }, DELAY);
          },
          DELAY * (inPlayCards?.length || 2)
        );
      }),
    [setGridWidth, inPlayCards?.length, sm]
  );

  const afterThrowCard = useCallback(
    async (res: ThrowCardResponse, cb: (isSuccess: boolean) => void = noop) => {
      const { bazzi, thoka } = res;

      const options = {
        onSuccess: async () => {
          await decreaseGridWidthGradually();
          cb(true);
        },
        onError: () => {
          cb(false);
        },
      };

      if (thoka) {
        applyThoka(undefined, options);

        return;
      }

      if (bazzi) {
        applyBazzi(undefined, options);

        return;
      }
    },
    [applyBazzi, applyThoka, decreaseGridWidthGradually]
  );

  return {
    afterThrowCard,
    isLoading: applyBazziOptions.isLoading || applyThokaOptions.isLoading,
    isError: applyBazziOptions.isError || applyThokaOptions.isError,
    applyBazziOptions,
    applyThokaOptions,
    decreaseGridWidthGradually,
  };
};

export const useThrowCardAnimation = (
  card: Card | null,
  setSelectedCard: React.Dispatch<
    React.SetStateAction<{
      card: Card;
      pos: CardPosition;
    } | null>
  >
) => {
  const {
    gameInfo: { inPlayCards },
  } = useSelector((state: Store) => state.inPlayReducer);
  const { setThrownCard } = useThrowCard();

  useEffect(() => {
    if (
      inPlayCards?.some(
        ({ suite, value }) => card?.suite === suite && card?.value === value
      )
    ) {
      setSelectedCard(null);
      setThrownCard(null);
    }
  }, [card?.suite, card?.value, inPlayCards, setSelectedCard, setThrownCard]);

  const md = useMediaQuery<Theme>(theme => theme.breakpoints.down('lg'));

  const motionProps: MotionProps = {
    animate: {
      y: -remToPx(!md ? 12 : 5),
      rotate: 360,
      transition: { duration: 1 },
    },
    initial: { y: 0, rotate: 0 },
    whileHover: { scale: 1.1 },
    whileTap: { scale: 1.1 },
  };

  return motionProps;
};

export const remToPx = (rem: number): number => {
  const rootFontSize = parseFloat(
    getComputedStyle(document.documentElement).fontSize
  );
  return rem * rootFontSize;
};

/**
 * Calling routes for bot play
 * @param wsRes
 * @param sendMessage
 * @param setGridWidth
 */
export const useBotPlay = (
  wsRes: InPlayRoomWsResponse | null,
  sendMessage = noop
) => {
  const { pathname } = useLocation();
  const queryOptions = useMemo(
    () => ({
      retry: 6,
      retryDelay: 800,
      enabled: pathname === IN_PLAY_ROUTE.ABSOLUTE_PATH,
    }),
    [pathname]
  );
  const { mutate } = useHttpMutation<ThrowCardResponse, { userID: string }>(
    API_URLS.BOT_PLAY,
    'POST',
    queryOptions
  );
  const { mutate: bazzi } = useHttpMutation(
    API_URLS.BAZZI,
    'GET',
    queryOptions
  );
  const { mutate: thoka } = useHttpMutation(
    API_URLS.THOKA,
    'GET',
    queryOptions
  );

  const { setGridWidth } = useThrowCard();
  const { decreaseGridWidthGradually } = useThokaBazzi(setGridWidth);

  const { gameInfo, players } = wsRes || {};
  const { room } = gameInfo || {};
  const { userCallBazzi, turnRes } = JSON.parse(
    room?.lastTurnResult || '{}'
  ) as LastTurnResult;

  const { id: currentUserID, isInRoom } = useSelector(
    (state: Store) => state.userReducer
  );

  const botPlayers = useMemo(
    () => players?.filter(({ userType }) => userType === 'bot'),
    [players]
  );

  const [botTookTurnAlready, setBotTookTurnAlready] = useState<string[]>([]);
  const { addAlert } = useAlert();

  useEffect(() => {
    if (turnRes?.thoka || turnRes?.bazzi) {
      setBotTookTurnAlready([]);
    }
  }, [turnRes?.bazzi, turnRes?.thoka]);

  useEffect(() => {
    if (
      room?.role !== 'owner' ||
      currentUserID === room?.nextTurn ||
      !isInRoom
    ) {
      return;
    }

    botPlayers?.forEach(({ id }) => {
      if (
        id === room?.nextTurn &&
        !botTookTurnAlready.includes(id) &&
        !turnRes?.bazzi &&
        !turnRes?.thoka &&
        room?.numberOfPlayers === players?.length
      ) {
        setBotTookTurnAlready(prev => [...prev, id]);
        setTimeout(() => {
          mutate(
            { userID: id },
            {
              onSuccess: () => {
                sendMessage('throw-card for bot');
              },
              onError: err => {
                LoggerService.error('Bot play failed: ', err);
                if (window.location.pathname === IN_PLAY_ROUTE.ABSOLUTE_PATH) {
                  addAlert({
                    type: 'error',
                    message:
                      'Slow connection, bot failed to throw card. Restart the App.',
                  });
                }
              },
            }
          );
        }, 3000);
      }
    });
  }, [
    addAlert,
    botPlayers,
    botTookTurnAlready,
    currentUserID,
    isInRoom,
    mutate,
    players?.length,
    room?.nextTurn,
    room?.numberOfPlayers,
    room?.role,
    sendMessage,
    turnRes?.bazzi,
    turnRes?.thoka,
  ]);

  useEffect(() => {
    if (room?.role !== 'owner' || !isInRoom) {
      return;
    }

    botPlayers?.forEach(({ id }) => {
      if (id === userCallBazzi) {
        if (turnRes.bazzi) {
          bazzi(undefined, {
            onSuccess: async () => {
              await decreaseGridWidthGradually();
              sendMessage('bazzi for bot');
            },
            onError: err => {
              LoggerService.error('Bot play failed: ', err);
              if (window.location.pathname === IN_PLAY_ROUTE.ABSOLUTE_PATH) {
                addAlert({
                  type: 'error',
                  message:
                    'Slow connection, bot failed to clear cards. Restart the App.',
                });
              }
            },
          });

          return;
        }

        if (turnRes.thoka) {
          thoka(undefined, {
            onSuccess: async () => {
              await decreaseGridWidthGradually();
              sendMessage('thoka for bot');
            },
            onError: err => {
              LoggerService.error('Bot play failed: ', err);
              if (window.location.pathname === IN_PLAY_ROUTE.ABSOLUTE_PATH) {
                addAlert({
                  type: 'error',
                  message:
                    'Slow connection, bot failed to play. Restart the App.',
                });
              }
            },
          });

          return;
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    addAlert,
    botPlayers,
    room?.role,
    turnRes?.bazzi,
    turnRes?.thoka,
    userCallBazzi,
    isInRoom,
  ]);
};
