import React, { FC } from 'react';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DoneIcon from '@mui/icons-material/Done';
import { DocumentData, Timestamp } from 'firebase/firestore';
import { Box, Theme, Typography, lighten, styled } from '@mui/material';
import { UserAvatar } from '../../../../shared/components';
import { useSelector } from 'react-redux';
import { Store } from '@/redux';

export interface BhabiFirestoreModel extends DocumentData {
  message: string;
  groupID: string;
  name: string;
  avatar: string;
  createdAt: Timestamp;
  senderID: string;
  receiverID: string;
  isRead: string;
  threadID: string[];
}

export const Message: FC<{
  message: BhabiFirestoreModel;
}> = ({ message }) => {
  const { firebaseUID } = useSelector((state: Store) => state.userReducer);

  return (
    <MessageWrapper
      sx={
        firebaseUID === message.senderID
          ? sxRight
          : {
              alignSelf: 'flex-start',
            }
      }
    >
      <UserAvatar
        hideState
        photoURL={message.avatar}
        name={message.name}
        fetchCurrentUser={firebaseUID === message.senderID}
        avatarProps={{
          sx: {
            ...sxLeft(),
            width: 25,
            height: 25,
          },
        }}
      />
      <Box minHeight={60}>
        <UserName>{message.name}</UserName>
        <UserMessage>{message.message}</UserMessage>

        <Typography
          {...{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: 0.5,
          }}
          variant="body2"
          textAlign="right"
        >
          {message.isRead === 'true' ? (
            <>
              <Typography variant="caption" color="textSecondary">
                seen
              </Typography>
              <DoneAllIcon fontSize="small" color="success" />
            </>
          ) : (
            <>
              <Typography variant="caption" color="textSecondary">
                sent
              </Typography>
              <DoneIcon fontSize="small" color="disabled" />
            </>
          )}
        </Typography>
      </Box>
    </MessageWrapper>
  );
};

const UserName = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  marginBottom: '5px',
  fontSize: '0.7rem',
  color: theme.palette.primary.main,
}));

const UserMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '0.8rem',
}));

const sxRight = (theme: Theme) => ({
  marginLeft: 'auto',
  borderRadius: '20px 20px 0 20px',
  backgroundColor: theme.palette.background.paper,
  boxShadow: '-2px 2px 1px 1px #88dded',
});

const sxLeft = () => ({
  width: '35px',
  height: '35px',
  borderRadius: '50%',
  marginRight: '10px',
});

const MessageWrapper = styled(Box)(({ theme }) => ({
  borderRadius: '20px 20px 20px 0',
  padding: theme.spacing(1),
  backgroundColor: lighten(theme.palette.background.paper, 0.2),
  color: theme.palette.text.primary,
  height: 'max-content',
  width: 'max-content',
  minHeight: '70px',
  maxWidth: 'calc(100% - 50px)',
  boxShadow: `-2px 2px 1px 1px #4c768d`,
  display: 'flex',
  alignItems: 'flex-start',
  marginBottom: '20px',
}));
