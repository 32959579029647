import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Card } from '../../types';
import {
  Box,
  BoxProps,
  Paper,
  SvgIconProps,
  Tooltip,
  Typography,
  styled,
  useTheme,
} from '@mui/material';

import { MotionProps, motion } from 'framer-motion';
import { BhabiStorage } from '../../../../shared/lib';
import { useSelector } from 'react-redux';
import { Store } from '../../../../redux';
import {
  ClubsIcon,
  DiamondsIcon,
  HeartsIcon,
  SpadesIcon,
} from '@/assets/card-icons';

export interface CommonDeckCardProps {
  card: Card;
}

type CardSuitsObject = {
  [key in Card['suite']]: (props?: SvgIconProps) => ReactNode;
};

export const symbol: CardSuitsObject = {
  spades: props => <SpadesIcon {...props} />, //'♠',
  hearts: props => <HeartsIcon {...props} />, //'♥',
  diamonds: props => <DiamondsIcon {...props} />, // '♦',
  clubs: props => <ClubsIcon {...props} />,
};

const useCardColor = () => {
  const theme = useTheme();

  const color: { [key in Card['suite']]: string } = {
    spades: theme.palette.grey[900],
    hearts: theme.palette.error.main,
    diamonds: theme.palette.error.main,
    clubs: theme.palette.grey[900],
  };

  return (suite: Card['suite']) => color[suite];
};

export interface PaperCardProps extends CommonDeckCardProps {
  disabled?: boolean;
  cardRef?: React.LegacyRef<HTMLDivElement>;
}
export const PaperCard: FC<PaperCardProps> = ({ card, disabled, cardRef }) => {
  const cardColor = useCardColor();

  return (
    <div ref={cardRef}>
      <PaperCardWrapper
        cardColor={cardColor(card.suite)}
        height={CARD_HEIGHT}
        width={CARD_WIDTH}
        component={Paper}
        sx={
          disabled
            ? {
                backgroundColor: theme => theme.palette.grey[700],
              }
            : undefined
        }
      >
        <Typography component="span">
          {symbol[card.suite]({ className: 'card-suit' })}
        </Typography>
        <CornerCardValue
          card={card}
          cardColor={cardColor(card.suite)}
          placement="topLeft"
        />
        <CornerCardValue
          card={card}
          cardColor={cardColor(card.suite)}
          placement="bottomRight"
          boxProps={{ flexDirection: 'column-reverse' }}
        />
      </PaperCardWrapper>
    </div>
  );
};

interface CornerCardValueProps extends CommonDeckCardProps {
  cardColor: string;
  placement: 'topLeft' | 'bottomRight';
  boxProps?: BoxProps;
}

const CornerCardValue: FC<CornerCardValueProps> = ({
  card: { value, suite },
  cardColor,
  placement,
  boxProps,
}) => (
  <Box
    display="flex"
    flexDirection="column"
    height={60}
    sx={{
      color: cardColor,
      position: 'absolute',
      ...(placement === 'bottomRight'
        ? {
            bottom: 0,
            right: 5,
            textAlign: 'right',
          }
        : {
            top: 0,
            left: 5,
          }),
    }}
    {...boxProps}
  >
    <Typography fontSize={CARD_FONT} fontWeight={800}>
      {value.trim()}
    </Typography>
    <Typography component="span">
      {symbol[suite]({ style: { height: 15, width: 15 } })}
    </Typography>
  </Box>
);

interface PaperCardWrapperProps {
  cardColor: string;
}

export const CARD_HEIGHT = {
  md: 100,
  sm: 90,
  lg: 170,
};
export const CARD_WIDTH = { md: 75, sm: 70, lg: 110 };
export const CARD_BORDER_RADIUS = 8;
export const CARD_FONT = {
  sm: '1rem',
  lg: '1.5rem',
};

const PaperCardWrapper = styled(Box, {
  shouldForwardProp: prop => prop !== 'cardColor',
})<PaperCardWrapperProps>(({ theme, cardColor }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(1),
  border: `1px solid ${theme.palette.grey[500]}`,
  backgroundColor: theme.palette.grey[300],
  cursor: 'pointer',
  borderRadius: CARD_BORDER_RADIUS,
  '& .card-suit': {
    color: cardColor,
    height: '1.5rem',
    width: '1.5rem',
    [theme.breakpoints.up('lg')]: {
      height: '3rem',
      width: '3rem',
    },
    fontWeight: 'bold',
  },
}));

export interface DeckCardProps extends PaperCardProps {
  motionDivProps?: MotionProps;
  tooltip?: boolean;
  onClick?: () => void;
  cardRef?: React.LegacyRef<HTMLDivElement>;
}

export const DeckCard: FC<DeckCardProps> = ({
  motionDivProps,
  onClick,
  tooltip,
  ...props
}) => {
  const [showTooltip, setShowTooltip] = useState(!!tooltip);
  const {
    gameInfo: { room },
  } = useSelector((state: Store) => state.inPlayReducer);

  useEffect(() => {
    BhabiStorage.getItem(res => {
      if ((res && res === room?.roomID) || !tooltip || !room?.roomID) {
        setShowTooltip(false);
        return;
      }

      setTimeout(() => {
        setShowTooltip(false);
        BhabiStorage.setItem('deckCardTooltip', room?.roomID);
      }, 3000);
    }, 'deckCardTooltip');
  }, [tooltip, room?.roomID]);

  return (
    <Tooltip
      title="Click on this card to throw it."
      arrow
      placement="top"
      open={showTooltip}
    >
      <motion.div
        onClick={props.disabled ? undefined : onClick}
        {...motionDivProps}
      >
        <PaperCard {...props} />
      </motion.div>
    </Tooltip>
  );
};
