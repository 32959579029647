import { noop } from 'lodash';
import React, {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from 'react';
import { SendMessage } from './use-websocket';

type SendSocketMessageCtx = {
  sendMessage: SendMessage | null;
  setSendMessage: React.Dispatch<React.SetStateAction<SendMessage | null>>;
};

const defaultCtx: SendSocketMessageCtx = {
  sendMessage: null,
  setSendMessage: noop,
};

const SendSocketMessageCtx = createContext(defaultCtx);

export const useSendSocketMessage = () => useContext(SendSocketMessageCtx);

export const SendWSMessageProvider: FC<PropsWithChildren> = ({ children }) => {
  const [sendMessage, setSendMessage] = useState<SendMessage | null>(null);

  return (
    <SendSocketMessageCtx.Provider
      value={{
        sendMessage,
        setSendMessage,
      }}
    >
      {children}
    </SendSocketMessageCtx.Provider>
  );
};
