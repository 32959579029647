import React, { FC } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { TextField, TextFieldProps } from '@mui/material';

export declare type SearchUserInput = {
  id: string;
  name: string;
  email: string;
  photoURL: string;
  firebaseUID: string;
  state: 'online' | 'offline' | 'joined' | 'rejoined' | 'left';
};

export interface SearchUserProps {
  textFieldProps?: TextFieldProps;
}

export const SearchUser: FC<SearchUserProps> = ({ textFieldProps }) => {
  return (
    <TextField
      variant="outlined"
      placeholder="Search for a user"
      InputProps={{
        startAdornment: <SearchIcon />,
        style: {
          height: 40,
        },
      }}
      sx={theme => ({
        width: '75%',
        [theme.breakpoints.down('md')]: {
          width: '100%',
        },
      })}
      {...textFieldProps}
    />
  );
};
