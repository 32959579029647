import { Store } from '@/redux';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { LoggerService } from '@/shared/logger';

export type SendMessage = (message: string) => void;

export const useWebsocket = <T = unknown>(websocketURL: string) => {
  const baseURL = useRef<string>(websocketURL);
  const { token } = useSelector((state: Store) => state.userReducer);
  const socketURL = useMemo(
    () => addTokenToWebsocketURL(baseURL.current, token),
    [token]
  );

  const websocketRef = useRef<WebSocket | null>(null);
  const [socketError, setSocketError] = useState(false);
  const [socketResponse, setResponse] = useState<T | null>(null);

  useEffect(() => {
    if (!socketURL) {
      LoggerService.error('Websocket URL not found...');
      return;
    }

    if (websocketRef.current) {
      return;
    }

    let ws: WebSocket | null = null;
    let retries = 0;

    const connectWebSocket = () => {
      ws = new WebSocket(new URL(socketURL));
      websocketRef.current = ws;

      ws.onopen = () => {
        LoggerService.info('Connected to websocket:: ', {
          socketURL: baseURL.current,
        });
        setSocketError(false);
      };

      ws.onmessage = event => {
        const data = JSON.parse(event.data);
        setResponse(data);
      };

      ws.onerror = error => {
        LoggerService.error('Error in websocket connection socket', {
          error,
          socketURL,
        });
      };

      ws.onclose = async e => {
        LoggerService.info(
          'Websocket connection closed, attempting to reconnect...'
        );

        if (e.code === 1000) {
          LoggerService.info('Websocket connection closed successfully');
          return;
        }

        if (retries > 5) {
          LoggerService.warn('Websocket connection failed after 5 retries');
          setSocketError(true);
          return;
        }

        retries += 1;
        await new Promise(resolve => setTimeout(resolve, 1000));
        connectWebSocket();
      };
    };

    connectWebSocket();

    return () => {
      if (websocketRef.current) {
        websocketRef.current.close(1000);
      }
    };
  }, [socketURL]);

  const sendMessage = useCallback((message: string) => {
    if (
      !websocketRef.current ||
      websocketRef.current.readyState !== WebSocket.OPEN
    ) {
      LoggerService.error('Websocket not connected :(');
      return;
    }
    websocketRef.current.send(message);
  }, []);

  return {
    socketResponse,
    ws: websocketRef.current,
    socketError,
    sendMessage,
  };
};

function addTokenToWebsocketURL(url: string, token: string | null) {
  if (!token) {
    return null;
  }
  return `${url}?token=Bearer ${token}`;
}
