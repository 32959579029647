import { useSelector } from 'react-redux';
import { dispatch, Store } from '../../redux';
import { useEffect } from 'react';
import { useFirebase } from '../lib';
import { getStoredMode, getUser, updateUserState } from '../../redux/reducers';

export const useIsAuth = () => {
  const user = useSelector((state: Store) => state.userReducer);
  const { isProcessing, checkFirebaseAuth } = useFirebase();

  useEffect(() => {
    checkFirebaseAuth(isAuth => {
      if (!isAuth) {
        dispatch(updateUserState({ isIdle: false }));
      }
    });
    dispatch(getStoredMode());
  }, [checkFirebaseAuth]);

  return {
    isAuthenticated: user?.isAuthenticated,
    isLoading: user?.isLoading || isProcessing,
    isError: user?.isError,
    token: user?.token,
    user,
    getUser,
    isIdle: user?.isIdle,
  };
};
