import React, { FC } from 'react';
import { useHttpMutation } from '../../../../../api';
import { API_URLS } from '../../../consts';
import { useAlert } from '../../../../../shared/components';
import { Button } from '@mui/material';
import { SendMessage } from '@/screens/authenticated/use-websocket';

export const RequestCardsButton: FC<{ sendMessage: SendMessage }> = ({
  sendMessage,
}) => {
  const { mutate: requestCards, isLoading } = useHttpMutation(
    API_URLS.REQUEST_CARDS,
    'GET'
  );

  const { addAlert } = useAlert();

  return (
    <Button
      variant="outlined"
      size="small"
      disabled={isLoading}
      onClick={() => {
        requestCards(undefined, {
          onSuccess: () => {
            addAlert({
              message: 'Cards requested from next player',
              type: 'success',
            });
            sendMessage('request-cards');
          },
        });
      }}
    >
      Request Cards
    </Button>
  );
};
